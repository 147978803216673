registerProcessor(
  'RhythmProcessor',
  class RhythmProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    lastReset = 0;
    position = -1;
    holding = true;
    sequence: Uint8Array;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.sequence = options.processorOptions.sequence;
      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'sequence':
            this.sequence = value;
            this.position =
              value.length === 0 ? 0 : this.position % value.length;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      { clock: [clock], reset: [reset] }: { [param: string]: Float32Array },
    ): boolean {
      if (this.sequence.length > 0) {
        if (this.lastReset <= 0 && reset > 0) {
          this.position = 0;
          this.port.postMessage(['position', 0]);
          this.holding = true;
        } else if (this.lastClock <= 0 && clock > 0) {
          this.position = (this.position + 1) % this.sequence.length;
          this.port.postMessage(['position', this.position]);
          this.holding = true;
        }
        this.lastReset = reset;
        this.lastClock = clock;

        const step = this.sequence[this.position];
        if (step === 1 && this.holding) {
          output[0] = 1;
          this.holding = false;
        } else if (step === 2) {
          output.fill(1);
        }
      }
      return true;
    }
  },
);
